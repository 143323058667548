import { AppConfig } from 'app/core/types/app-config.types';

export const config: AppConfig = {
  theme: 'dark',
  contentWidth: 'full',
  layout: 'empty',
  sidebar: {
    mode: 'side',
    hasBackdrop: false,
    disableClose: true,
    position: 'start'
  }
};

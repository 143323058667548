<div class="navigation-group-wrapper">
  <div class="navigation-group-title">
    <mat-icon class="inline-block flex-shrink-0 icon-size-5"
              *ngIf="navigation.icon"
              [svgIcon]="navigation.icon"></mat-icon>
    <div class="leading-none">
      <span class="block">{{ navigation.title }}</span>
      <span class="block text-gray-300 text-xs"
            *ngIf="navigation.subtitle">{{ navigation.subtitle }}</span>
    </div>
  </div>

  <div class="navigation-group-children">
    <ng-container *ngFor="let child of navigation.children">
      <af-navigation-basic [navigation]="child"></af-navigation-basic>
    </ng-container>
  </div>
</div>

import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { merge } from 'lodash';

import { AppConfig } from 'app/core/types/app-config.types';
import { ADMIN_FLOW_APP_CONFIG } from './config.token';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private readonly _configBehaviourSubject;

  constructor(@Inject(ADMIN_FLOW_APP_CONFIG) private _config: AppConfig) {
    this._configBehaviourSubject = new BehaviorSubject(_config);
  }

  set config(value: AppConfig) {
    const config = merge({}, this._configBehaviourSubject.getValue(), value);

    this._configBehaviourSubject.next(config);
  }

  get config$(): Observable<AppConfig> {
    return this._configBehaviourSubject.asObservable();
  }
}

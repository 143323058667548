<div class="navigation-basic-wrapper">

  <!--Internal link-->
  <ng-container *ngIf="navigation.link && !navigation.isExternal && !navigation.disabled">
    <a class="navigation-basic-link"
       [routerLink]="navigation.link"
       routerLinkActive="navigation-basic-link-active" [routerLinkActiveOptions]="{exact: true}">
      <ng-container *ngTemplateOutlet="navigationBasicTemplate"></ng-container>
    </a>
  </ng-container>

  <!--External link-->
  <ng-container *ngIf="navigation.link && navigation.isExternal && !navigation.disabled">
    <a class="navigation-basic-link"
       [href]="navigation.link"
       [target]="navigation.target || '_self'">
      <ng-container *ngTemplateOutlet="navigationBasicTemplate"></ng-container>
    </a>
  </ng-container>

  <!--Disabled link-->
  <ng-container *ngIf="navigation.disabled">
    <div class="navigation-basic-link navigation-basic-link-disabled">
      <mat-icon class="inline-block flex-shrink-0 icon-size-5"
                *ngIf="navigation.icon"
                [svgIcon]="navigation.icon"></mat-icon>
      <div class="leading-none">
        <span class="block">{{ navigation.title }}</span>
        <span class="block text-gray-400 text-xs"
              *ngIf="navigation.subtitle">{{ navigation.subtitle }}</span>
      </div>
    </div>
  </ng-container>
</div>


<ng-template #navigationBasicTemplate>
  <mat-icon class="inline-block flex-shrink-0 icon-size-5"
            *ngIf="navigation.icon"
            [svgIcon]="navigation.icon"></mat-icon>
  <div class="leading-none">
    <span class="block">{{ navigation.title }}</span>
    <span class="block text-gray-300 text-xs"
          *ngIf="navigation.subtitle">{{ navigation.subtitle }}</span>
  </div>
</ng-template>

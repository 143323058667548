import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';

export const collapse = [
  trigger('collapse', [
    state('collapsed, void', style({
      height: 0
    })),

    state('expanded, *', style({
      height: '*'
    })),

    transition('void => false', []),

    transition('collapsed <=> expanded, void <=> *', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
  ])
];

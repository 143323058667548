import {
  ChangeDetectionStrategy,
  Component, Input,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'af-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LogoComponent {
  @Input() animated: boolean = false;
}

import { NgModule } from '@angular/core';
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { HighlightComponent } from './highlight.component';

// https://www.npmjs.com/package/ngx-highlightjs
@NgModule({
  declarations: [
    HighlightComponent
  ],
  imports: [HighlightModule],
  providers: [
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          typescript: () => import('highlight.js/lib/languages/typescript'),
          scss: () => import('highlight.js/lib/languages/scss'),
          xml: () => import('highlight.js/lib/languages/xml')
        }
      }
    }
  ],
  exports: [
    HighlightComponent
  ]
})
export class AdminFlowHighlightModule {
}

import { Role } from 'app/core/models/system/user';

export const me = {
  id: 1,
  firstName: 'John',
  lastName: 'Doe',
  email: 'johndoe@example.com',
  role: Role.USER,
  createDate: '2022-03-15'
};

import {
  AfterViewInit,
  Component,
  ElementRef,
  EmbeddedViewRef,
  Input,
  OnChanges,
  SecurityContext,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import { HighlightJS } from 'ngx-highlightjs';
import { HighlightResult } from 'ngx-highlightjs/lib/highlight.model';
import { take } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'textarea[afHighlight]',
  template: `
    <ng-content></ng-content>

    <ng-template let-code="code">
    <pre>
      <code class="hljs" [innerHTML]="code"></code>
    </pre>
    </ng-template>
  `,
  styles: [`
    textarea[afHighlight] {
      @apply hidden;
    }
  `],
  encapsulation: ViewEncapsulation.None
})
export class HighlightComponent implements AfterViewInit, OnChanges {
  @Input()
  code: string;
  @Input()
  language: string;
  @ViewChild(TemplateRef) templateRef: TemplateRef<any>;

  private _embeddedViewRef: EmbeddedViewRef<any>;

  constructor(
    private _elementRef: ElementRef<HTMLTextAreaElement>,
    private _hljs: HighlightJS,
    private _viewContainerRef: ViewContainerRef,
    private _domSanitizer: DomSanitizer
  ) {
  }

  ngAfterViewInit(): void {
    if (!this.code) {
      this.code = this._elementRef.nativeElement.value;
    }

    this._highlightCode();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && !changes.code && !changes.language) {
      return;
    }

    this._highlightCode();
  }

  private _highlightCode(): void {
    if (!this.code || !this.language) {
      return;
    }

    this.code = this._formatCode();

    this._hljs.highlight(this.code, {
      language: this.language,
      ignoreIllegals: true
    }).pipe(take(1))
      .subscribe(highlightResult => {
        this._insertHighlightedCode(highlightResult);
      });
  }

  private _insertHighlightedCode(highlightResult: HighlightResult): void {
    if (highlightResult && !highlightResult.value) {
      return;
    }

    if (this._embeddedViewRef) {
      this._embeddedViewRef.destroy();
      this._embeddedViewRef = null;
    }

    let sanitizedHighlightedCode = this._domSanitizer.sanitize(SecurityContext.HTML, highlightResult.value as string);

    this._embeddedViewRef = this._viewContainerRef.createEmbeddedView(this.templateRef, {
      code: sanitizedHighlightedCode
    });

    this._embeddedViewRef.detectChanges();
  }

  private _formatCode() {
    let spacesCount = 0;
    let codeLines = this.code.split('\n');

    codeLines = this._removeEmptyLinesFromStart(codeLines);
    codeLines = this._removeEmptyLinesFromEnd(codeLines);

    codeLines.forEach((line, index) => {
      if (index === 0) {
        spacesCount = line.search(/\S|$/);
        return;
      }

      spacesCount = Math.min(line.search(/\S|$/), spacesCount);
    });

    return codeLines.map(line => line.substring(spacesCount)).join('\n');
  }

  private _removeEmptyLinesFromStart(codeLines: string[]) {
    while (codeLines.length && !codeLines[0].trim()) {
      codeLines.shift();
    }

    return codeLines;
  }

  private _removeEmptyLinesFromEnd(codeLines: string[]) {
    while (codeLines.length && !codeLines[codeLines.length - 1].trim()) {
      codeLines.pop();
    }

    return codeLines;
  }
}

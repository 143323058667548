import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  prefix: string = environment.prefix;

  constructor() {
  }

  setItem(key: string, value: string): void {
    localStorage.setItem(this._constructKey(key), value);
  }

  getItem(key: string): string {
    return localStorage.getItem(this._constructKey(key));
  }

  removeItem(key: string): void {
    localStorage.removeItem(this._constructKey(key));
  }

  private _constructKey(key: string): string {
    return `${this.prefix}-${key}`;
  }
}

import { Role } from 'app/core/models/system/user';

export const users = [
  {
    id: 1,
    firstName: 'John',
    lastName: 'Doe',
    email: 'johndoe@example.com',
    role: Role.USER,
    createDate: '2022-03-15'
  },
  {
    id: 2,
    firstName: 'Jane',
    lastName: 'Smith',
    email: 'janesmith@example.com',
    role: Role.ADMIN,
    createDate: '2021-09-28'
  },
  {
    id: 3,
    firstName: 'Alex',
    lastName: 'Johnson',
    email: 'alexjohnson@example.com',
    role: Role.USER,
    createDate: '2023-01-10'
  },
  {
    id: 4,
    firstName: 'Emily',
    lastName: 'Davis',
    email: 'emilydavis@example.com',
    role: Role.USER,
    createDate: '2022-11-05'
  },
  {
    id: 5,
    firstName: 'David',
    lastName: 'Johnson',
    email: 'davidjohnson@example.com',
    role: Role.USER,
    createDate: '2023-04-02'
  },
  {
    id: 6,
    firstName: 'Michael',
    lastName: 'Wilson',
    email: 'michaelwilson@example.com',
    role: Role.USER,
    createDate: '2023-02-12'
  },
  {
    id: 7,
    firstName: 'Sarah',
    lastName: 'Thompson',
    email: 'sarahthompson@example.com',
    role: Role.USER,
    createDate: '2023-01-28'
  },
  {
    id: 8,
    firstName: 'Christopher',
    lastName: 'Taylor',
    email: 'christophertaylor@example.com',
    role: Role.USER,
    createDate: '2022-12-05'
  },
  {
    id: 9,
    firstName: 'Jessica',
    lastName: 'Anderson',
    email: 'jessicaanderson@example.com',
    role: Role.USER,
    createDate: '2022-11-21'
  },
  {
    id: 10,
    firstName: 'Daniel',
    lastName: 'Martinez',
    email: 'danielmartinez@example.com',
    role: Role.USER,
    createDate: '2022-10-09'
  }
];

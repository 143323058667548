import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input
} from '@angular/core';

import { User, UserColor } from 'app/core/models/system/user';

@Component({
  selector: 'af-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserAvatarComponent {
  @Input() user: User;

  @HostBinding('class') get classes(): string[] {
    return this.hasImage
      ? []
      : [(this.user.avatar as UserColor).bgColor, (this.user.avatar as UserColor).textColor];
  }

  constructor() {
  }

  get hasImage(): boolean {
    return typeof this.user.avatar === 'string';
  }

  get imageSrc(): string {
    return (this.user as any).avatar ?? null;
  }
}

import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

import { ConfigModule } from 'app/core/services/config/config.module';
import { LayoutModule } from './components/layout/layout.module';
import { config } from './services/config/config';
import { IconsModule } from './icons/icons.module';
import { HttpClientWepApiModule } from 'app/core/services/http-client-wep-api/http-client-wep-api.module';
import { LocalStorageModule } from 'app/core/services/local-storage/local-storage.module';
import { AccessTokenModule } from './services/access-token/access-token.module';

@NgModule({
  imports: [
    HttpClientModule,
    HttpClientWepApiModule,
    ConfigModule.forRoot(config),
    LayoutModule,
    IconsModule,
    LocalStorageModule,
    AccessTokenModule
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill',
        floatLabel: 'always',
        subscriptSizing: 'dynamic'
      }
    }
  ]
})
export class CoreModule {
}

import { NgModule } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

import {
  VerticalNavigationComponent
} from './vertical-navigation/vertical-navigation.component';
import {
  NavigationBasicComponent
} from './vertical-navigation/components/navigation-basic/navigation-basic.component';
import { NavigationGroupComponent } from './vertical-navigation/components/navigation-group/navigation-group.component';
import { NavigationCollapsableComponent } from './vertical-navigation/components/navigation-collapsable/navigation-collapsable.component';
import { LogoModule } from 'app/core/components/logo/logo.module';

@NgModule({
  declarations: [
    VerticalNavigationComponent,
    NavigationBasicComponent,
    NavigationGroupComponent,
    NavigationCollapsableComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    RouterLink,
    RouterLinkActive,
    LogoModule
  ],
  exports: [
    VerticalNavigationComponent,
    NavigationBasicComponent
  ]
})
export class NavigationModule {
}

<div class="navigation-collapsable-wrapper">
  <div class="navigation-collapsable-title"
       [ngClass]="{'navigation-collapsable-collapsed': !isCollapsed}"
       (click)="toggleCollapse()">
    <mat-icon class="inline-block flex-shrink-0 icon-size-5"
              *ngIf="navigation.icon"
              [svgIcon]="navigation.icon"></mat-icon>
    <div class="leading-none flex-1">
      <span class="block">{{ navigation.title }}</span>
      <span class="block text-gray-300 text-xs"
            *ngIf="navigation.subtitle">{{ navigation.subtitle }}</span>
    </div>

    <mat-icon class="chevron-icon"
              svgIcon="feather:chevron_right"></mat-icon>
  </div>

  <div class="navigation-collapsable-children"
       [@collapse]="isCollapsed ? 'collapsed' : 'expanded'"
       *ngIf="!isCollapsed">
    <ng-container *ngFor="let child of navigation.children">
      <ng-container *ngIf="child.type === 'basic'">
        <af-navigation-basic [navigation]="child"></af-navigation-basic>
      </ng-container>

      <ng-container *ngIf="child.type === 'group'">
        <af-navigation-group [navigation]="child"></af-navigation-group>
      </ng-container>

      <ng-container *ngIf="child.type === 'collapsable'">
        <af-navigation-collapsable [navigation]="child"></af-navigation-collapsable>
      </ng-container>
    </ng-container>
  </div>
</div>

import {
  AfterViewInit,
  Component,
  ElementRef, Inject,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';

import {
  NavigationItem
} from './components/navigation-basic/navigation-basic.types';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'af-vertical-navigation',
  templateUrl: './vertical-navigation.component.html',
  styleUrls: ['./vertical-navigation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VerticalNavigationComponent implements AfterViewInit {
  @ViewChild('navigationsWrapper') navigationsWrapper: ElementRef<HTMLDivElement>;

  navigation: NavigationItem[] = [
    {
      id: 'dashboard',
      type: 'collapsable',
      title: 'Dashboards',
      icon: 'feather:grid',
      children: [
        {
          id: 'dashboard-1',
          title: 'Dashboard 1',
          type: 'basic',
          link: '/dashboard/dashboard-1',
          icon: 'feather:grid'
        },
        {
          id: 'dashboard-2',
          title: 'Dashboard 2',
          type: 'basic',
          link: '/dashboard/dashboard-2',
          icon: 'feather:grid'
        }
      ]
    },
    {
      id: 'shop',
      type: 'collapsable',
      title: 'Shop',
      link: '/shop',
      icon: 'feather:shopping_bag',
      children: [
        {
          id: 'products',
          title: 'Products',
          type: 'basic',
          link: '/shop/products',
          icon: 'feather:shopping_bag'
        },
        {
          id: 'product-view',
          title: 'Product View',
          type: 'basic',
          link: '/shop/products/1',
          icon: 'feather:eye'
        },
        {
          id: 'orders',
          title: 'Orders',
          type: 'basic',
          link: '/shop/orders',
          icon: 'feather:shopping_cart'
        }
      ]
    },
    {
      id: 'icons',
      type: 'collapsable',
      title: 'Icons',
      icon: 'feather:image',
      children: [
        {
          id: 'feather',
          title: 'Feather',
          type: 'basic',
          link: '/icons/feather',
          icon: 'feather:image'
        },
        {
          id: 'material-baseline',
          title: 'Material Baseline',
          type: 'basic',
          link: '/icons/material/baseline',
          icon: 'feather:image'
        },
        {
          id: 'material-outline',
          title: 'Material Outline',
          type: 'basic',
          link: '/icons/material/outline',
          icon: 'feather:image'
        },
        {
          id: 'material-round',
          title: 'Material Round',
          type: 'basic',
          link: '/icons/material/round',
          icon: 'feather:image'
        },
        {
          id: 'material-sharp',
          title: 'Material Sharp',
          type: 'basic',
          link: '/icons/material/sharp',
          icon: 'feather:image'
        },
        {
          id: 'material-twotone',
          title: 'Material Twotone',
          type: 'basic',
          link: '/icons/material/twotone',
          icon: 'feather:image'
        }
      ]
    },
    {
      id: 'material-components',
      title: 'Angular material',
      type: 'collapsable',
      icon: 'feather:layout',
      subtitle: 'Angular material components with updated styles',
      children: [
        {
          id: 'general',
          title: 'General',
          type: 'collapsable',
          children: [
            {
              id: 'button',
              title: 'Button',
              link: 'components/button',
              type: 'basic'
            },
            {
              id: 'button-toggle',
              title: 'Button toggle',
              link: 'components/button-toggle',
              type: 'basic'
            },
            {
              id: 'icon',
              title: 'Icon',
              link: 'components/icon',
              type: 'basic'
            },
            {
              id: 'chips',
              title: 'Chips',
              link: 'components/chips',
              type: 'basic'
            },
            {
              id: 'dialog',
              title: 'Dialog',
              link: 'components/dialog',
              type: 'basic'
            },
            {
              id: 'menu',
              title: 'Menu',
              link: 'components/menu',
              type: 'basic'
            },
            {
              id: 'slide-toggle',
              title: 'Slide toggle',
              link: 'components/slide-toggle',
              type: 'basic'
            },
            {
              id: 'snackbar',
              title: 'Snackbar',
              link: 'components/snackbar',
              type: 'basic'
            },
            {
              id: 'table',
              title: 'Table',
              link: 'components/table',
              type: 'basic'
            },
            {
              id: 'tabs',
              title: 'Tabs',
              link: 'components/tabs',
              type: 'basic'
            },
            {
              id: 'tooltip',
              title: 'Tooltip',
              link: 'components/tooltip',
              type: 'basic'
            }
          ]
        },
        {
          id: 'form-fields',
          title: 'Form Fields',
          type: 'collapsable',
          children: [
            {
              id: 'input',
              title: 'Input',
              link: 'components/input',
              type: 'basic'
            },
            {
              id: 'autocomplete',
              title: 'Autocomplete',
              link: 'components/autocomplete',
              type: 'basic'
            },
            {
              id: 'checkbox',
              title: 'Checkbox',
              link: 'components/checkbox',
              type: 'basic'
            },
            {
              id: 'datepicker',
              title: 'Datepicker',
              link: 'components/datepicker',
              type: 'basic'
            },
            {
              id: 'select',
              title: 'Select',
              link: 'components/select',
              type: 'basic'
            }
          ]
        }
      ]
    },
    {
      id: 'users',
      title: 'Users',
      type: 'basic',
      icon: 'feather:user',
      link: 'users'
    },
    {
      id: 'settings',
      title: 'Settings',
      type: 'basic',
      icon: 'feather:settings',
      link: 'settings'
    },
    {
      id: 'components',
      title: 'Components',
      type: 'collapsable',
      icon: 'feather:layout',
      children: [
        {
          id: 'highlight',
          title: 'Highlight',
          link: 'components/highlight',
          type: 'basic'
        },
        {
          id: 'divider',
          title: 'Divider',
          link: 'components/divider',
          type: 'basic'
        },
        {
          id: 'loading-spinner',
          title: 'Loading spinner',
          link: 'components/loading-spinner',
          type: 'basic'
        },
        {
          id: 'card',
          title: 'Card',
          link: 'components/card',
          type: 'basic'
        },
        {
          id: 'alert',
          title: 'Alert',
          link: 'components/alert',
          type: 'basic'
        },
        {
          id: 'confirmation-dialog',
          title: 'Confirmation dialog',
          link: 'components/confirmation-dialog',
          type: 'basic'
        }
      ]
    },
    {
      id: 'pages',
      title: 'Pages',
      type: 'collapsable',
      icon: 'feather:layout',
      children: [
        {
          id: 'sign-in',
          title: 'Sign in',
          type: 'basic',
          link: '/sign-in',
          icon: 'feather:user'
        },
        {
          id: 'sign-up',
          title: 'Sign up',
          type: 'basic',
          link: '/sign-up',
          icon: 'feather:user_plus'
        },
        {
          id: 'forgot-password',
          title: 'Forgot password',
          type: 'basic',
          link: '/forgot-password',
          icon: 'feather:lock'
        },
        {
          id: 'maintenance',
          title: 'Maintenance',
          type: 'basic',
          link: '/maintenance',
          icon: 'feather:alert_circle'
        },
        {
          id: 'error-404',
          title: '404 error',
          type: 'basic',
          link: '/error-404',
          icon: 'feather:alert_circle'
        },
        {
          id: 'error-500',
          title: '500 error',
          type: 'basic',
          link: '/error-500',
          icon: 'feather:alert_circle'
        },
        {
          id: 'pricing',
          title: 'Pricing',
          type: 'basic',
          link: '/pricing',
          icon: 'feather:dollar_sign'
        }
      ]
    },
    {
      id: 'tailwindcss',
      title: 'TailwindCSS',
      type: 'basic',
      icon: 'feather:layout',
      link: 'components/tailwindcss'
    },
    {
      id: 'chart',
      title: 'Chart',
      type: 'basic',
      icon: 'feather:pie_chart',
      link: 'components/chart'
    }
  ];

  private _ps: PerfectScrollbar;

  constructor(private _elRef: ElementRef, @Inject(DOCUMENT) private _document: Document) {
  }

  ngAfterViewInit(): void {
    this._ps = new PerfectScrollbar(this._elRef.nativeElement);

    setTimeout(() => {
      this._scrollToActiveNav();
    });
  }

  trackByFn(index: number, item: NavigationItem): string {
    return item.id || `vertical-navigation-item-${ index }`;
  }

  private _scrollToActiveNav() {
    if (!this.navigationsWrapper) {
      return;
    }

    const activeNav = this.navigationsWrapper.nativeElement.querySelector('.navigation-basic-link-active');
    const center = this._document.defaultView.innerHeight / 2;

    if (activeNav && (activeNav.getBoundingClientRect().top > center)) {
      activeNav.scrollIntoView();
    }
  }
}

import { Component, Input } from '@angular/core';

import { User } from 'app/core/models/system/user';

@Component({
  selector: 'af-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent {
  @Input() user: User = new User({
    firstName: 'John',
    lastName: 'Doe',
    email: 'john@doe.com'
  });
}

import { Component, Input } from '@angular/core';

import { User } from 'app/core/models/system/user';

@Component({
  selector: 'af-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent {
  @Input()
  user: User;
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';
import { MatDrawerMode } from '@angular/material/sidenav';

import {
  NavigationItem
} from 'app/core/components/navigation/vertical-navigation/components/navigation-basic/navigation-basic.types';

@Component({
  selector: 'af-vertical',
  templateUrl: './vertical.component.html',
  styleUrls: ['./vertical.component.scss']
})
export class VerticalComponent implements OnInit, OnDestroy {
  footerNavs: NavigationItem[] = [
    {
      id: 'support',
      title: 'Support',
      type: 'basic',
      icon: 'feather:smile',
      link: '#'
    },
    {
      id: 'settings',
      title: 'Settings',
      type: 'basic',
      icon: 'feather:tool',
      link: '#'
    }
  ];
  drawerMode: MatDrawerMode = 'side';
  drawerOpened: boolean = true;

  private _subscription: Subscription;
  private _breakpointsMap: Map<string, string> = new Map([
    [Breakpoints.XSmall, 'XSmall'],
    [Breakpoints.Small, 'Small'],
    [Breakpoints.Medium, 'Medium']
  ]);

  constructor(private _breakpointObserver: BreakpointObserver) {
  }

  ngOnInit(): void {
    this._subscription = this._breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium
    ]).subscribe(result => {
      this._updateDrawerState(result.breakpoints);
    });
  }

  ngOnDestroy(): void {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }

  trackByNavs(index: number, item: NavigationItem): string {
    return item.id;
  }

  private _updateDrawerState(breakpoints: { [p: string]: boolean }) {
    for (const [breakpoint, isActive] of Object.entries(breakpoints)) {
      if (!isActive) {
        continue;
      }

      const isSmall = this._isSmall(breakpoint);
      if (isSmall) {
        this.drawerMode = 'over';
        this.drawerOpened = false;
        continue;
      }

      this.drawerMode = 'side';
      this.drawerOpened = true;
    }
  }

  private _isSmall(breakpoint: string) {
    return this._breakpointsMap.get(breakpoint) === 'Small' || this._breakpointsMap.get(breakpoint) === 'XSmall';
  }
}

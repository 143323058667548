<div class="flex flex-col">
  <a class="text-black-900 hover:text-black-900 hover:no-underline" routerLink="/">
    <af-logo class="w-full text-3xl"></af-logo>
  </a>

  <div class="mt-14 space-y-2" #navigationsWrapper>
    <ng-container *ngFor="let item of navigation; trackBy: trackByFn">

      <ng-container *ngIf="item.type === 'basic'">
        <af-navigation-basic [navigation]="item"></af-navigation-basic>
      </ng-container>

      <ng-container *ngIf="item.type === 'group'">
        <af-navigation-group [navigation]="item"></af-navigation-group>
      </ng-container>

      <ng-container *ngIf="item.type === 'collapsable'">
        <af-navigation-collapsable [navigation]="item"></af-navigation-collapsable>
      </ng-container>
    </ng-container>
  </div>
</div>

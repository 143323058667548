import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

import { UserComponent } from './user.component';
import { UserInfoModule } from '_admin-flow/components/user-info/user-info.module';
import { UserAvatarModule } from '_admin-flow/components/user-avatar/user-avatar.module';

@NgModule({
  declarations: [UserComponent],
  imports: [
    CommonModule,
    MatIconModule,
    UserInfoModule,
    UserAvatarModule
  ],
  exports: [
    UserComponent
  ]
})
export class UserModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LayoutComponent } from './layout.component';
import { HorizontalModule } from './horizontal/horizontal.module';
import { VerticalModule } from './vertical/vertical.module';
import { EmptyModule } from './empty/empty.module';

@NgModule({
  declarations: [LayoutComponent],
  imports: [
    CommonModule,
    HorizontalModule,
    VerticalModule,
    EmptyModule
  ],
})
export class LayoutModule {}

import { NgModule } from '@angular/core';

import { LoadingModule } from './services/loading/loading.module';
import { AdminFlowDirectivesModule } from './directives/directives.module';

@NgModule({
  imports: [
    AdminFlowDirectivesModule,
    LoadingModule
  ]
})
export class AdminFlowModule { }

export const featherIcons = [
  'activity',
  'airplay',
  'alert_circle',
  'alert_octagon',
  'alert_triangle',
  'align_center',
  'align_justify',
  'align_left',
  'align_right',
  'anchor',
  'aperture',
  'archive',
  'arrow_down_circle',
  'arrow_down_left',
  'arrow_down_right',
  'arrow_down',
  'arrow_left_circle',
  'arrow_left',
  'arrow_right_circle',
  'arrow_right',
  'arrow_up_circle',
  'arrow_up_left',
  'arrow_up_right',
  'arrow_up',
  'at_sign',
  'award',
  'bar_chart_2',
  'bar_chart',
  'battery_charging',
  'battery',
  'bell_off',
  'bell',
  'bluetooth',
  'bold',
  'book_open',
  'book',
  'bookmark',
  'box',
  'briefcase',
  'calendar',
  'camera_off',
  'camera',
  'cast',
  'check_circle',
  'check_square',
  'check',
  'chevron_down',
  'chevron_left',
  'chevron_right',
  'chevron_up',
  'chevrons_down',
  'chevrons_left',
  'chevrons_right',
  'chevrons_up',
  'chrome',
  'circle',
  'clipboard',
  'clock',
  'cloud_drizzle',
  'cloud_lightning',
  'cloud_off',
  'cloud_rain',
  'cloud_snow',
  'cloud',
  'code',
  'codepen',
  'codesandbox',
  'coffee',
  'columns',
  'command',
  'compass',
  'copy',
  'corner_down_left',
  'corner_down_right',
  'corner_left_down',
  'corner_left_up',
  'corner_right_down',
  'corner_right_up',
  'corner_up_left',
  'corner_up_right',
  'cpu',
  'credit_card',
  'crop',
  'crosshair',
  'database',
  'delete',
  'disc',
  'divide_circle',
  'divide_square',
  'divide',
  'dollar_sign',
  'download_cloud',
  'download',
  'dribbble',
  'droplet',
  'edit_2',
  'edit_3',
  'edit',
  'external_link',
  'eye_off',
  'eye',
  'facebook',
  'fast_forward',
  'feather',
  'figma',
  'file_minus',
  'file_plus',
  'file_text',
  'file',
  'film',
  'filter',
  'flag',
  'folder_minus',
  'folder_plus',
  'folder',
  'framer',
  'frown',
  'gift',
  'git_branch',
  'git_commit',
  'git_merge',
  'git_pull_request',
  'github',
  'gitlab',
  'globe',
  'grid',
  'hard_drive',
  'hash',
  'headphones',
  'heart',
  'help_circle',
  'hexagon',
  'home',
  'image',
  'inbox',
  'info',
  'instagram',
  'italic',
  'key',
  'layers',
  'layout',
  'life_buoy',
  'link_2',
  'link',
  'linkedin',
  'list',
  'loader',
  'lock',
  'log_in',
  'log_out',
  'mail',
  'map_pin',
  'map',
  'maximize_2',
  'maximize',
  'meh',
  'menu',
  'message_circle',
  'message_square',
  'mic_off',
  'mic',
  'minimize_2',
  'minimize',
  'minus_circle',
  'minus_square',
  'minus',
  'monitor',
  'moon',
  'more_horizontal',
  'more_vertical',
  'mouse_pointer',
  'move',
  'music',
  'navigation_2',
  'navigation',
  'octagon',
  'package',
  'paperclip',
  'pause_circle',
  'pause',
  'pen_tool',
  'percent',
  'phone_call',
  'phone_forwarded',
  'phone_incoming',
  'phone_missed',
  'phone_off',
  'phone_outgoing',
  'phone',
  'pie_chart',
  'play_circle',
  'play',
  'plus_circle',
  'plus_square',
  'plus',
  'pocket',
  'power',
  'printer',
  'radio',
  'refresh_ccw',
  'refresh_cw',
  'repeat',
  'rewind',
  'rotate_ccw',
  'rotate_cw',
  'rss',
  'save',
  'scissors',
  'search',
  'send',
  'server',
  'settings',
  'share_2',
  'share',
  'shield_off',
  'shield',
  'shopping_bag',
  'shopping_cart',
  'shuffle',
  'sidebar',
  'skip_back',
  'skip_forward',
  'slack',
  'slash',
  'sliders',
  'smartphone',
  'smile',
  'speaker',
  'square',
  'star',
  'stop_circle',
  'sun',
  'sunrise',
  'sunset',
  'table',
  'tablet',
  'tag',
  'target',
  'terminal',
  'thermometer',
  'thumbs_down',
  'thumbs_up',
  'toggle_left',
  'toggle_right',
  'tool',
  'trash_2',
  'trash',
  'trello',
  'trending_down',
  'trending_up',
  'triangle',
  'truck',
  'tv',
  'twitch',
  'twitter',
  'type',
  'umbrella',
  'underline',
  'unlock',
  'upload_cloud',
  'upload',
  'user_check',
  'user_minus',
  'user_plus',
  'user_x',
  'user',
  'users',
  'video_off',
  'video',
  'voicemail',
  'volume_1',
  'volume_2',
  'volume_x',
  'volume',
  'watch',
  'wifi_off',
  'wifi',
  'wind',
  'x_circle',
  'x_octagon',
  'x_square',
  'x',
  'youtube',
  'zap_off',
  'zap',
  'zoom_in',
  'zoom_out'
];

import { Injectable } from '@angular/core';
import { LocalStorageService } from '../local-storage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AccessTokenService {
  private readonly _tokenKey = 'access-token';

  constructor(private _localStorageService: LocalStorageService) {
  }

  set token(token: string) {
    this._localStorageService.setItem(this._tokenKey, token);
  }

  get token(): string {
    return this._localStorageService.getItem(this._tokenKey);
  }

  clear(): void {
    this._localStorageService.removeItem(this._tokenKey);
  }

  isExpired(): boolean {
    if (!this.token) {
      return true;
    }

    const now = Date.now() / 1000;
    const expiresDate = this._getExpirationDate();
    return true;
  }

  private _getExpirationDate(): number {
    const decoded = this._decode();

    console.log(decoded);

    return 0;
  }

  private _decode() {
    const parts = this.token.split('.');

    if (parts.length < 3) {
      throw new Error('JWT token is invalid!');
    }

    return parts;
  }
}

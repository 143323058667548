import { Component, Input, ViewEncapsulation } from '@angular/core';
import { NavigationItem } from '../navigation-basic/navigation-basic.types';

@Component({
  selector: 'af-navigation-group',
  templateUrl: './navigation-group.component.html',
  styleUrls: ['./navigation-group.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavigationGroupComponent {
  @Input()
  navigation: NavigationItem;
}

import { NgModule } from '@angular/core';
import { AccessTokenService } from './access-token.service';

@NgModule({
  providers: [
    AccessTokenService
  ]
})
export class AccessTokenModule {
}

import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { collapse } from '_admin-flow/animations';
import { NavigationItem } from '../navigation-basic/navigation-basic.types';

@Component({
  selector: 'af-navigation-collapsable',
  templateUrl: './navigation-collapsable.component.html',
  styleUrls: ['./navigation-collapsable.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: collapse
})
export class NavigationCollapsableComponent implements OnInit, OnDestroy {
  @Input() navigation: NavigationItem;

  isCollapsed: boolean = true;

  private _unsubscribeAll: Subject<void> = new Subject<void>();

  constructor(private _router: Router) {
  }

  ngOnInit(): void {
    this.isCollapsed = !this._hasActiveChild(this.navigation, this._router.url);

    this._router.events
      .pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(event => {
        this.isCollapsed = !this._hasActiveChild(this.navigation, event.urlAfterRedirects);
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  toggleCollapse(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  private _hasActiveChild(item: NavigationItem, urlAfterRedirects: string): boolean {
    const { children } = item;

    if (!children) {
      return false;
    }

    for (const child of children) {
      if (child.children) {
        if (this._hasActiveChild(child, urlAfterRedirects)) {
          return true;
        }
      }

      if (child.link && this._router.isActive(child.link, {
        paths: 'subset',
        queryParams: 'subset',
        fragment: 'ignored',
        matrixParams: 'ignored'
      })) {
        return true;
      }
    }

    return false;
  }
}

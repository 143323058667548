import { NgModule } from '@angular/core';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';

import { environment } from 'environments/environment';
import { HttpClientWepApiService } from './http-client-wep-api.service';

@NgModule({
  imports: [
    environment.production
      ? []
      : HttpClientInMemoryWebApiModule.forRoot(HttpClientWepApiService, {
        passThruUnknownUrl: true,
        delay: 500
      })
  ]
})
export class HttpClientWepApiModule {
}

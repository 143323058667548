export class User {
  id?: number;
  firstName: string;
  lastName: string;
  email: string;
  role?: Role;
  createDate?: string;
  avatar?: string | UserColor;

  constructor(user?: Partial<User>) {
    this.id = user.id;
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.email = user.email;
    this.role = user.role || Role.USER;
    this.createDate = user.createDate || new Date().toString();
    this.avatar = user.avatar || this._getUserColor();
  }

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  get isAdmin(): boolean {
    return this.role === Role.ADMIN
  }

  private _getUserColor(): UserColor {
    return colors[randomNumber()];
  }
}

export enum Role {
  ADMIN = 'admin',
  USER = 'user'
}

export type UserColor = {
  bgColor: string,
  textColor: string
}

export type UserColors = UserColor[];

const colors: UserColors = [
  {
    bgColor: 'bg-red-100',
    textColor: 'text-red-800'
  },
  {
    bgColor: 'bg-blue-100',
    textColor: 'text-blue-800'
  },
  {
    bgColor: 'bg-green-100',
    textColor: 'text-green-800'
  },
  {
    bgColor: 'bg-purple-100',
    textColor: 'text-purple-800'
  },
  {
    bgColor: 'bg-orange-100',
    textColor: 'text-orange-800'
  },
  {
    bgColor: 'bg-amber-100',
    textColor: 'text-amber-800'
  },
  {
    bgColor: 'bg-yellow-100',
    textColor: 'text-yellow-800'
  }
];

function randomNumber(): number {
  return Math.floor(Math.random() * colors.length);
}

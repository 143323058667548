import { GalleryItem, ImageItem } from 'ng-gallery';

export class Product {
  brand: string;
  category: string;
  description: string;
  discountPercentage: number;
  id: number;
  images: string[];
  galleryItems: GalleryItem[];
  price: number;
  comparePrice: number;
  rating: number;
  stock: number;
  thumbnail: string;
  title: string;
  isFavourite?: boolean;

  constructor(product: Partial<Product>) {
    this.brand = product.brand;
    this.category = product.category;
    this.description = product.description;
    this.discountPercentage = product.discountPercentage;
    this.id = product.id;
    this.images = product.images;
    this.galleryItems = this._getGalleryItems();
    this.price = product.price;
    this.comparePrice = this._getComparePrice();
    this.rating = product.rating;
    this.stock = product.stock;
    this.thumbnail = product.thumbnail;
    this.title = product.title;
    this.isFavourite = product.isFavourite ?? false;
  }

  toggleFavourite() {
    this.isFavourite = !this.isFavourite;
  }

  private _getComparePrice(): number {
    return Math.round(this.price + this.price * (this.discountPercentage / 100));
  }

  private _getGalleryItems() {
    return this.images.map(image => new ImageItem({
      src: image,
      thumb: image
    }));
  }
}

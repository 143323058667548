import { Injectable } from '@angular/core';
import { InMemoryDbService } from 'angular-in-memory-web-api';

import { users } from './data/users';
import { featherIcons } from './data/feather-icons';
import { materialIcons } from './data/material-icons';
import { servicesIncome } from './data/services-income';
import { products } from './data/products';
import { orders } from './data/orders';
import { me } from './data/me';
import { countries } from './data/countries';

@Injectable({
  providedIn: 'root'
})
export class HttpClientWepApiService implements InMemoryDbService {
  constructor() {
  }

  createDb(): {} {
    return {
      users,
      products,
      orders,
      me,
      countries,
      'feather-icons': featherIcons,
      'material-icons': materialIcons,
      'services-income': servicesIncome
    };
  }
}

import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { ConfigService } from 'app/core/services/config/config.service';
import { AppConfig } from 'app/core/types/app-config.types';
import { Layout } from 'app/core/types/layout.types';

@Component({
  selector: 'af-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LayoutComponent implements OnInit, OnDestroy {
  layout: Layout;
  config: AppConfig;

  private _unsubscribeAll: Subject<void>;

  constructor(
    private _configService: ConfigService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute
  ) {
    this._unsubscribeAll = new Subject<void>();
  }

  ngOnInit(): void {
    this._configService.config$
      .pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe(value => {
      this.config = value;

      this._updateLayout();
    });

    this._router.events
      .pipe(
        takeUntil(this._unsubscribeAll),
        filter(event => event instanceof NavigationEnd)
      ).subscribe(() => this._updateLayout());
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private _updateLayout(): void {
    this.layout = this.config.layout;
    let route = this._activatedRoute;

    while (route.firstChild) {
      route = route.firstChild;
    }

    route.pathFromRoot.forEach((path) => {
      if (path.routeConfig && path.routeConfig.data && path.routeConfig.data.layout) {
        this.layout = path.routeConfig.data.layout;
      }
    });
  }
}

import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { LoadingService } from '_admin-flow/services/loading/loading.service';

@Component({
  selector: 'af-loading-bar',
  templateUrl: './loading-bar.component.html',
  styleUrls: ['./loading-bar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoadingBarComponent implements OnInit, OnDestroy {
  show: boolean;

  private _unsubscribeAll: Subject<void> = new Subject<void>();

  constructor(private _loadingService: LoadingService) {
  }

  ngOnInit(): void {
    this._loadingService.show$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((show) => {
        this.show = show;
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}

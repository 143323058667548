import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatMenuModule } from '@angular/material/menu';

import { VerticalComponent } from './vertical.component';
import {
  NavigationModule
} from 'app/core/components/navigation/navigation.module';
import {
  LoadingBarModule
} from '_admin-flow/components/loading-bar/loading-bar.module';
import { UserModule } from 'app/core/components/user/user.module';
import {
  DividerModule
} from '_admin-flow/components/divider/divider.module';

@NgModule({
  declarations: [VerticalComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatSidenavModule,
    MatButtonModule,
    NavigationModule,
    MatToolbarModule,
    MatIconModule,
    MatProgressBarModule,
    LoadingBarModule,
    UserModule,
    DividerModule,
    MatMenuModule
  ],
  exports: [VerticalComponent]
})
export class VerticalModule {
}

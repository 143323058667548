import { NgModule } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

@NgModule({})
export class IconsModule {
  constructor(private _matIconRegistry: MatIconRegistry, private _domSanitizer: DomSanitizer) {
    // Register custom icons
    this._matIconRegistry.addSvgIconSetInNamespace('feather', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/feather.svg'));
    this._matIconRegistry.addSvgIconSetInNamespace('social', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/social.svg'));
    this._matIconRegistry.addSvgIconSetInNamespace('brand', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/brands.svg'));

    // Material icons
    this._matIconRegistry.addSvgIconSetInNamespace('mat_baseline', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material/baseline.svg'));
    this._matIconRegistry.addSvgIconSetInNamespace('mat_outline', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material/outline.svg'));
    this._matIconRegistry.addSvgIconSetInNamespace('mat_round', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material/round.svg'));
    this._matIconRegistry.addSvgIconSetInNamespace('mat_sharp', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material/sharp.svg'));
    this._matIconRegistry.addSvgIconSetInNamespace('mat_twotone', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material/twotone.svg'));
  }
}

import { Component, Input } from '@angular/core';
import { NavigationItem } from './navigation-basic.types';

@Component({
  selector: 'af-navigation-basic',
  templateUrl: './navigation-basic.component.html',
  styleUrls: ['./navigation-basic.component.scss']
})
export class NavigationBasicComponent {
  @Input()
  navigation: NavigationItem;
}

<div class="vertical-layout">
  <af-loading-bar></af-loading-bar>
  <mat-drawer-container class="w-full">
    <mat-drawer #drawer [mode]="drawerMode" [opened]="drawerOpened">
      <div class="drawer-side-wrapper">
        <div class="navigation-main">
          <af-vertical-navigation></af-vertical-navigation>
        </div>

        <div class="px-6">
          <af-divider></af-divider>
        </div>

        <div class="navigation-footer">
          <ng-container *ngFor="let nav of footerNavs; trackBy: trackByNavs">
            <af-navigation-basic [navigation]="nav"></af-navigation-basic>
          </ng-container>

          <af-user class="af-user" [matMenuTriggerFor]="menu"></af-user>

          <mat-menu #menu="matMenu" class="w-44">
            <button mat-menu-item>
              <mat-icon class="icon-size-5" svgIcon="feather:user"></mat-icon>
              <span>Profile</span>
            </button>
            <button mat-menu-item>
              <mat-icon class="icon-size-5" svgIcon="feather:tool"></mat-icon>
              <span>Settings</span>
            </button>
            <af-divider></af-divider>
            <a class="hover:no-underline" mat-menu-item routerLink="/sign-in">Logout</a>
          </mat-menu>
        </div>
      </div>
    </mat-drawer>

    <mat-drawer-content>
      <div class="af-drawer-content">
        <mat-toolbar class="af-toolbar">
          <button mat-icon-button
                  class="hamburger-button"
                  aria-label="Menu icon"
                  (click)="drawer.toggle()">
            <mat-icon svgIcon="feather:menu"></mat-icon>
          </button>
        </mat-toolbar>

        <div class="af-router-wrapper">
          <router-outlet></router-outlet>
        </div>
      </div>
    </mat-drawer-content>

  </mat-drawer-container>
</div>

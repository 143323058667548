export const servicesIncome = [
  {
    'id': 1,
    'serviceName': 'BI',
    'projectsCount': 5,
    'income': 100000
  },
  {
    'id': 2,
    'serviceName': 'IT Consulting',
    'projectsCount': 8,
    'income': 265093
  },
  {
    'id': 3,
    'serviceName': 'Data Science',
    'projectsCount': 30,
    'income': 334668
  },
  {
    'id': 4,
    'serviceName': 'Data Engineering',
    'projectsCount': 5,
    'income': 280963
  },
  {
    'id': 5,
    'serviceName': 'Frontend Development',
    'projectsCount': 29,
    'income': 312749
  },
  {
    'id': 6,
    'serviceName': 'Fullstack Development',
    'projectsCount': 9,
    'income': 413376
  },
  {
    'id': 7,
    'serviceName': 'Backend Development',
    'projectsCount': 15,
    'income': 261205
  },
  {
    'id': 8,
    'serviceName': 'Machine Learning',
    'projectsCount': 11,
    'income': 379318
  },
  {
    'id': 9,
    'serviceName': 'System Analysis',
    'projectsCount': 12,
    'income': 162856
  }
];

<div class="flex items-center space-x-1.5">
  <svg viewBox="0 0 80.01868008211139 84.69757381501633"
       class="h-8 w-8 min-w-[32px]"
       [ngClass]="{'animate-bounce': animated}"
       height="84.69757381501633"
       width="80.01868008211139">
    <svg xmlns="http://www.w3.org/2000/svg"
         x="0"
         y="0"
         viewBox="8.338866853817704 5.307672287355221 84.07618164545373 88.99232771264478"
         height="84.69757381501633"
         width="80.01868008211139"
         class="icon-icon-0"
         data-fill-palette-color="accent"
         id="icon-0">
      <path d="M39.5 94.3c-3.6 0-7.2-0.7-10.6-2.3-8.3-3.7-14-11.2-15.2-20.2L8.6 35C7.2 25 11.5 15.4 19.8 9.8c8.3-5.6 18.8-6 27.5-1l32.1 18.4c7.8 4.5 12.7 12.5 13 21.5s-3.9 17.4-11.3 22.5l-27 18.4C49.7 92.8 44.6 94.3 39.5 94.3zM34.3 14.3c-3.3 0-6.6 1-9.5 3-5.5 3.7-8.3 9.9-7.3 16.4l5.1 36.9c0.8 5.9 4.5 10.8 10 13.2 5.4 2.4 11.6 1.8 16.5-1.5l27-18.4c4.9-3.3 7.6-8.8 7.4-14.7C83.2 43.3 80 38 74.9 35.1L42.9 16.6C40.2 15.1 37.2 14.3 34.3 14.3z"
            fill="#6b21a8"
            data-fill-palette-color="accent"></path>
    </svg>
  </svg>
  <span class="font-semibold">AdminFlow</span>
</div>

import { ModuleWithProviders, NgModule } from '@angular/core';

import { ADMIN_FLOW_APP_CONFIG } from './config.token';
import { AppConfig } from 'app/core/types/app-config.types';
import { ConfigService } from './config.service';

@NgModule({})
export class ConfigModule {
  constructor(private _configService: ConfigService) {
  }

  static forRoot(config: AppConfig): ModuleWithProviders<ConfigModule> {
    return {
      ngModule: ConfigModule,
      providers: [
        {
          provide: ADMIN_FLOW_APP_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}

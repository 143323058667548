import { Routes } from '@angular/router';
import { LayoutComponent } from 'app/core/components/layout/layout.component';

export const rootRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard/dashboard-1'
  },
  // Free routes
  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'empty'
    },
    children: [
      {
        path: 'sign-in',
        pathMatch: 'full',
        loadChildren: () =>
          import('app/core/components/auth/sign-in/sign-in.module').then(
            (m) => m.SignInModule
          )
      },
      {
        path: 'sign-up',
        loadChildren: () =>
          import('app/core/components/auth/sign-up/sign-up.module').then(
            (m) => m.SignOutModule
          )
      },
      {
        path: 'forgot-password',
        loadChildren: () =>
          import('app/core/components/auth/forgot-password/forgot-password.module').then(
            (m) => m.ForgotPasswordModule
          )
      }
    ]
  },
  // Routes for authorized user
  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'vertical'
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('app/modules/dashboard/dashboard.module').then(
          (m) => m.DashboardModule
        )
      },
      {
        path: 'icons',
        loadChildren: () => import('app/modules/icons/icons.module').then((m) => m.IconsModule)
      },
      {
        path: 'shop',
        loadChildren: () => import('app/modules/shop/shop.module').then((m) => m.ShopModule)
      },
      {
        path: 'components',
        loadChildren: () => import('app/modules/components/components.module').then((m) => m.ComponentsModule)
      },
      {
        path: 'pricing',
        loadChildren: () => import('app/modules/pricing/pricing.module').then((m) => m.PricingModule)
      },
      {
        path: 'users',
        loadChildren: () => import('app/modules/users/users.module').then((m) => m.UsersModule)
      },
      {
        path: 'settings',
        loadChildren: () => import('app/modules/user-profile/user-profile.module').then((m) => m.UserProfileModule)
      }
    ]
  },

  // Error pages
  {
    path: 'maintenance',
    pathMatch: 'full',
    loadChildren: () =>
      import('app/core/components/error-pages/maintance/maintance.module').then(
        (m) => m.MaintanceModule
      )
  },
  {
    path: 'error-404',
    pathMatch: 'full',
    loadChildren: () =>
      import('app/core/components/error-pages/error-404/error-404.module').then(
        (m) => m.Error404Module
      )
  },
  {
    path: 'error-500',
    pathMatch: 'full',
    loadChildren: () =>
      import('app/core/components/error-pages/error-500/error-500.module').then(
        (m) => m.Error500Module
      )
  },
  { path: '**', redirectTo: 'error-404' }
];

import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { DividerOrientation } from './divider-orientation.types';
import { orientationClassNames } from './orientation-class-names';

@Component({
  selector: 'af-divider',
  templateUrl: './divider.component.html',
  styleUrls: ['./divider.component.scss']
})
export class DividerComponent implements OnInit {
  @Input()
  text: string | undefined;
  @Input()
  orientation: DividerOrientation = 'left';

  @HostBinding('class')
  get classNames(): string | null {
    return [
      this.withTextClassName,
      this.orientationClassName
    ].join(' ');
  }

  constructor() {
  }

  ngOnInit(): void {
  }

  get withTextClassName(): string | null {
    return this.text ? 'divider-with-text' : null;
  }

  get orientationClassName(): string | null {
    return this.orientation && this.text ? orientationClassNames[this.orientation] : null;
  }
}
